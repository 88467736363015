import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import {Link} from "gatsby";


let pageData = {
    pageUrl: '/404/',
    pageTitle: '404'
};

const NotFoundPage = () => (
    <>
        <Header pageUrl={pageData.pageUrl}/>
        <SEO title="404 - not found"/>
        <div className="page404">
            <Link to="/" className="button button__bordered">Перейти на главную страницу</Link>
        </div>
        <Footer/>
    </>
)

export default NotFoundPage
